import React from 'react';
import classNames from 'classnames';
import FormDialog from "../shared/FormDialog";
import ConfirmDelete from "../shared/ConfirmDelete";
import update from 'immutability-helper';
import Select from 'react-select';
import SelectStyles from '../../utils/SelectStyles';

import {
    TextField,
    Button,
    Fab,
    Card,
    CardActions,
    CardContent,
    Divider,
    Icon,
    Typography,
    InputAdornment
} from '@mui/material';

class Clients extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {

        super(props);

        this.searchRef = React.createRef();
        this.handleKey = this.handleKey.bind(this);

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args));
            },
            confirmDelete: (...args) => {
                this.setState(this.props.confirmDelete(this.state, args));
            },
            handleClickOpen: (...args) => {
                this.setState(this.props.handleClickOpen(args));
            },
            handleRequestClose: (...args) => {
                this.setState(this.props.handleRequestClose(this.state, args));
            },
            validate: (...args) => {

                // Make sure that there are no duplicate CID's inputted on the backend...
                // this will cause problems and doubling of the data
                const noDupes = this.noDuplicateIds();
                const noInvalidNumbers = this.noInvalidNumberValues();

                if ( noDupes && noInvalidNumbers ) {
                    const results = this.props.validate(this.state, args);
                    this.setState(results.state);
                    return results.validated;
                }
                else {
                    if ( !noDupes ) {
                        alert('Duplicate IDs not allowed.');
                    } else if ( !noInvalidNumbers ) {
                        alert('Number values > 1 and < 0 are not allowed');
                    }

                    return false;
                }
            },
            generateInputState: (...args) => {
                return this.props.generateInputState(args);
            },
            generateErrorState: (...args) => {
                return this.props.generateErrorState(args);
            }
        };

        const markupFieldProps = {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01,
            type: 'number'
        };

        const stringFieldProps = {
            default: '',
            type: 'text',
            nullable: true
        };

        const checkboxFieldProps = {
            type: 'checkbox',
            default: false,
            noValidate: true
        };

        this.clientProps = [
            'adwords_sem_account_id',
            'adwords_seim_account_id',
            'adwords_spanish_seim_account_id',
            'adwords_display_account_id',
            'bing_sem_account_id',
            'bing_seim_account_id',
            'marchex_id',
            'gtm_account_id',
            'gtm_container_id'
        ];

        this.customStyles = {
            control: styles => ({ ...styles,
                width: '200px'
            }),
            menu: styles => ({
                ...SelectStyles.menu,
                width: '200px'
            })
        };

        this.form = {
            rows: [
                {
                    label: 'Client ID',
                    inputs: [
                        {
                            name: 'Client ID',
                            key: 'id',
                            ...stringFieldProps,
                            type: 'readonly'
                        }
                    ]
                },
                {
                    label: 'Client',
                    inputs: [
                        {
                            name: 'Client Name',
                            key: 'name',
                            errorText: 'Client name cannot be empty.',
                            ...stringFieldProps,
                            nullable: false
                        },
                        {
                            name: 'Acronym',
                            key: 'acronym',
                            ...stringFieldProps
                        },
                        {
                            name: 'Group Code',
                            key: 'group',
                            ...stringFieldProps
                        },
                    ]
                },
                {
                    label: 'Client Details',
                    inputs: [
                        {
                            name: 'Domain',
                            key: 'domain',
                            ...stringFieldProps
                        },
                        {
                            name: 'Web Vendor',
                            key: 'web_vendor',
                            ...stringFieldProps
                        },
                        {
                            name: 'Industry',
                            key: 'industry',
                            ...stringFieldProps
                        },
                    ]
                },
                {
                    label: 'Client Extras',
                    inputs: [
                        {
                            name: 'Phone',
                            key: 'phone',
                            ...stringFieldProps
                        },
                        {
                            name: 'Agency Partner (White Label)',
                            key: 'agency_partner',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Address',
                    inputs: [
                        {
                            name: 'Address',
                            key: 'address',
                            ...stringFieldProps
                        },
                        {
                            name: 'Latitude',
                            key: 'latitude',
                            ...stringFieldProps
                        },
                        {
                            name: 'Longitude',
                            key: 'longitude',
                            ...stringFieldProps
                        },
                    ]
                },
                {
                    label: 'NinjaCat',
                    inputs: [
                        {
                            name: 'NinjaCat ID',
                            key: 'ninjacat_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Google Ads',
                    inputs: [
                        {
                            name: 'AdWords SEM Account ID',
                            key: 'adwords_sem_account_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'AdWords English SEIM Account ID',
                            key: 'adwords_seim_account_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'AdWords Spanish SEIM Account ID',
                            key: 'adwords_spanish_seim_account_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'AdWords Display Account ID',
                            key: 'adwords_display_account_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Google Merchant Center',
                    inputs: [
                        {
                            name: 'Google Merchant Center Owner Account ID',
                            key: 'google_merchant_center_owner_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'Google Merchant Center Store Account ID',
                            key: 'google_merchant_center_store_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'Google Merchant Center Store Code',
                            key: 'google_merchant_center_store_code_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'Google SEIM VLA Account',
                            key: 'seim_vla_account',
                            ...checkboxFieldProps
                        }
                    ]
                },
                {
                    label: 'Microsoft Ads (Bing)',
                    inputs: [
                        {
                            name: 'Bing SEM Account ID',
                            key: 'bing_sem_account_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'Bing SEIM Account ID',
                            key: 'bing_seim_account_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Google Analytics 4 (GA4)',
                    inputs: [
                        {
                            name: 'GA4 Email',
                            key: 'ga4_email',
                            ...stringFieldProps
                        },
                        {
                            name: 'GA4 Property Id',
                            key: 'ga4_property_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Marchex',
                    inputs: [
                        {
                            name: 'Marchex ID',
                            key: 'marchex_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Facebook',
                    inputs: [
                        {
                            name: 'Facebook ID',
                            key: 'facebook_account_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'Facebook Page ID',
                            key: 'fb_page_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Asana',
                    inputs: [
                        {
                            name: 'Asana Project Id',
                            key: 'asana_project_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Apple Search Ads',
                    inputs: [
                        {
                            name: 'Apple Org Id',
                            key: 'apple_search_ads_org_id',
                            ...stringFieldProps
                        }
                    ]
                },
                {
                    label: 'Google Tag Manaer (GTM)',
                    inputs: [
                        {
                            name: 'GTM Account ID',
                            key: 'gtm_account_id',
                            ...stringFieldProps
                        },
                        {
                            name: 'GTM Container ID',
                            key: 'gtm_container_id',
                            ...stringFieldProps
                        },
                    ]
                },
            ]
        };

        this.state = {
            inputs: this.sharedMethods.generateInputState(this.form),
            errors: this.sharedMethods.generateErrorState(this.form),
            searchMethod: {value:'name', label:'Name'},
            search: '',
            clients: [],
            filtered: [],
            skip: 0,
            limit: 10,
            filteredCount: 0,
            open: false,
            edit: false,
            editId: null,
            deleteIds: null,
            showTags: true,
            confirmDeleteOpen: false
        };
    }

    handleKey(event) {

        if(this.searchRef.current === document.activeElement) {
            return;
        }

        switch(
            event.keyCode
        )
        {
            case 78: // n
                this.clientSearchKeyChange(
                    {
                        value: 'name',
                        label: 'Name'
                    }
                )
                event.preventDefault();
                break;
            case 65: // a
                this.clientSearchKeyChange(
                    {
                        value: 'acronym',
                        label: 'Acronym'
                    }
                )
                event.preventDefault();
                break;
            case 73: // i
                this.clientSearchKeyChange(
                    {
                        value: 'id',
                        label: 'ID'
                    }
                )
                event.preventDefault();
                break;

            default:
                //

        }

    }

    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'client/index'
        })
            .then( data => {

                this.props.stopLoading();

                this.setState({
                    clients: data
                }, () => {
                    this.searchClients();
                });

            });

    }

    /**
     *
     * Make sure that there are no duplicate CID's inputted on the backend...
     * this will cause problems and doubling of the data
     *
     */
    noDuplicateIds = () => {

        let inputs = this.state.inputs;

        let accountIds = [
            'adwords_sem_account_id',
            'adwords_display_account_id',
            'adwords_seim_account_id',
            'adwords_spanish_seim_account_id',
            'bing_seim_account_id',
            'bing_sem_account_id'
        ];

        accountIds = accountIds.map( acctId => inputs[acctId] ? inputs[acctId] : acctId );
        return (new Set(accountIds)).size === accountIds.length;

    };

    /**
     *
     * @returns {boolean}
     */
    noInvalidNumberValues = () => {

        let numberTypeInputs = this.form.rows
            .reduce( ( obj, { inputs=[] } ) => {

                inputs = inputs
                    .reduce( ( inputObj, input ) => {

                        let {type,key,min=0,max=1} = input;

                        if ( type === 'number' ) {
                            return ({ ...inputObj, ...{
                                [key]: {
                                    min: min,
                                    max: max
                                }
                            } });
                        } else {
                            return inputObj;
                        }

                    }, {} );

                return({...obj,...inputs});

            }, {} );

        let invalidKeys = Object.keys( this.state.inputs )
            .filter( key =>
                (
                    typeof this.state.inputs[key] === 'number'
                    && key.includes('markup')
                ) && (
                    this.state.inputs[key] < numberTypeInputs[key].min
                    || this.state.inputs[key] > numberTypeInputs[key].max
                )
            );

        return !invalidKeys.length;

    };

    /**
     *
     */
    submitClient() {

        if (this.sharedMethods.validate(this.form)) {

            this.props.startLoading();
            const url = this.state.edit ? 'client/update' : 'client/store';

            let payLoad;

            if (this.state.edit) {
                payLoad = {
                    inputs: this.state.inputs,
                    id: this.state.editId
                };
            }
            else {
                payLoad = this.state.inputs;
            }

            this.sharedMethods.handleRequestClose(
                this.sharedMethods.generateInputState(this.form),
                this.sharedMethods.generateErrorState(this.form)
            );

            this.props.API({
                service: 'management',
                route: url,
                data: payLoad
            })
                .then(data => {

                    this.props.stopLoading();

                    this.setState(update(this.state, {
                        clients: {$set: data },
                    }), () => {
                        let e = {target:{value:this.state.search}};
                        this.searchClients(e)
                    });

                });
        }
    }

    /**
     *
     * @param id
     * @param index
     */
    deleteClient(id, index) {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'client/destroy/'+id
        })
            .then(data => {

                this.props.stopLoading();

                if (this.state.filtered) {
                    this.setState(update(this.state, {
                        confirmDeleteOpen: {$set: false },
                        filtered: {$set: null },
                        search: {$set: '' },
                        clients: {$set: data }
                    }));
                }
                else {
                    this.setState(update(this.state, {
                        clients: {$splice: [[index, 1]] },
                        confirmDeleteOpen: {$set: false }
                    }));
                }
            });
    }

    /**
     *
     * @param client
     * @param id
     */
    editClient(client, id) {

        let inputs = {};
        Object.keys(client).forEach((key) => {
            inputs[key] = client[key];
        });

        this.sharedMethods.handleClickOpen(update(this.state, {
            inputs: {$set: inputs },
            edit: {$set: true },
            editId: {$set: id }
        }));
    }

    /**
     *
     * @param e
     */
    searchClients(e) {

        clearTimeout(window.whatever);

        let search = '';
        let resetSkip = false;

        if(e?.target?.value === '') {
            resetSkip = true;
            search = '';
        } else if (!e?.target?.value) {
            search = this.state.search
        } else {
            resetSkip = true;
            search = e.target.value;
        }

        this.setState(
            {
                search: search,
                skip: resetSkip ? 0 : this.state.skip
            },
            () => {

                window.whatever = setTimeout(()=> {
                    let f =  [ ...(this.state.clients ?? []).filter(
                        (c) => this.state.search === ''
                            ? true
                            : (c[this.state.searchMethod?.value] ?? '')
                                .toString()
                                .toLowerCase()
                                .trim()
                                .indexOf(this.state.search.toLowerCase())
                                > -1
                    ) ];
                    this.setState({
                        filtered: [...f].slice(this.state.skip, this.state.skip+this.state.limit),
                        filteredCount: f.length
                    });
                }, 300 );

            }
        );

    }

    clientSearchKeyChange(e) {
        this.setState({
            searchMethod: e
        }, () => {
            this.searchRef?.current?.focus();
            this.searchClients()
        });
    }

    getClientDetails(client) {

        return this.clientProps.map( prop => {

            const upper = [
                'sem',
                'seim',
                'id'
            ];

            const name = prop
                .split('_')
                .map( n => {
                    if ( upper.includes(n) ) return n.toLocaleUpperCase();
                    return `${n.charAt(0).toUpperCase()}${n.slice(1)}`;
                })
                .join(' ');

            return client[prop]
                ? (
                    <div className="d-flex" key={client.id+'-'+prop}>

                        <h6>{
                            `${name}:`
                        }</h6>

                        <span className="value">{
                            client[prop]
                        }</span>

                    </div>
                )
                : null;

        });

    }

    getShowTags() {

        return this.state.showTags && this.state.filtered ? this.state.filtered.map( (client) => {

            return (
                <div
                    key={client.id}
                    className={
                        classNames(
                            'd-flex',
                            'client-row',
                            'justify-content-start'
                        )
                    }
                >
                    <Card raised classes={{
                        root: 'card'
                    }}>
                        <CardContent>

                            <div className={classNames('d-flex','justify-content-between','align-items-center')}>

                                <Typography variant="h5">{client.name}</Typography>
                                <div>
                                    <Typography variant="subtitle1">{client.id}</Typography>
                                    <Typography variant="subtitle1">{client.acronym}</Typography>
                                </div>
                            </div>

                            <div className={classNames('d-flex flex-column')}>{
                                this.getClientDetails(client)
                            }</div>

                        </CardContent>

                        <Divider light/>

                        <CardActions classes={{
                            root: 'card-action'
                        }}>

                            <Button
                                variant='contained'
                                onClick={() => this.editClient(client, client.id)}
                                classes={{
                                    root: 'client-edit'
                                }} >

                                <Icon>mode_edit</Icon>
                                Edit
                            </Button>


                            <Button
                                variant='contained'
                                onClick={() => this.sharedMethods.confirmDelete(client.id, c)}
                                classes={{
                                    root: 'client-delete'
                                }} >

                                <Icon>delete</Icon>
                                Delete

                            </Button>

                        </CardActions>

                    </Card>
                </div>
            );
        }) : null;

    }
    getPages()
    {

        const perc = this.state.skip/this.state.filteredCount;
        const pageCount = this.state.filteredCount/this.state.limit;

        const pages = Math.floor(
            pageCount
        ) + 1;

        const page = Math.floor(
            perc * pages
        ) + 1;

        let showing = null;

        if(
            page === pages
            || this.state.skip+this.state.limit > this.state.filteredCount
        ) {
            showing = this.state.filteredCount;
        } else {
            showing = this.state.skip+this.state.limit;
        }

        return <div style={{marginTop:'20px'}}>

            <small style={{fontSize:'1rem'}}>{`
                page ${isNaN(page) ? 1 : page} of ${pages}
                (
                    showing ${
                        showing
                    }
                    of ${
                        this.state.filteredCount
                    }
                )
            `}</small>

            {
                this.state.filteredCount !== this.state.filtered.length
                ?
                <div>
                    <button style={{marginRight:'5px'}} className="btn btn-primary" disabled={!(page > 1)} onClick={()=>{this.prev();}}>prev</button>
                    <button className="btn btn-primary" disabled={!(page < pages)} onClick={()=>{this.next();}}>next</button>
                </div>
                : null
            }
            <br/>
            <Select
                tabIndex="4"
                styles={this.customStyles}
                key='search-limit'
                isMulti={false}
                placeholder='Limit'
                value={{value: this.state.limit, label: `Limit: ${this.state.limit}`}}
                onChange={ (e) => {
                    this.setState({
                        limit: e.value,
                        skip: 0
                    }, () => {
                        this.searchClients()
                    });
                } }
                options={
                    [
                        {
                            value: 3,
                            label: "Limit: 3"
                        },
                        {
                            value: 10,
                            label: "Limit: 10"
                        },
                        {
                            value: 20,
                            label: "Limit: 20"
                        },
                        {
                            value: 30,
                            label:  "Limit: 30"
                        },
                        {
                            value: 50,
                            label:  "Limit: 50"
                        },
                    ]
                }
            />

        </div>;

    }

    next() {

        const nx = this.state.skip+this.state.limit;

        if(
            nx > this.state.filteredCount
        ) return;

        this.setState({
            skip: nx
        }, () => {
            this.searchClients();
        });

    }

    prev() {

        const pv = this.state.skip-this.state.limit;

        if(
            pv < 0
        ) return;

        this.setState({
            skip: pv
        }, () => {
            this.searchClients();
        });

    }

    /**
     *
     * @returns {*[]}
     */
    render() {
        return [
            <div id="clients" key="clients"  tabIndex="0" onKeyDown={this.handleKey}>

                <div className="d-flex" style={{marginBottom:'10px'}}>
                    <Fab size='small'
                            tabIndex="3"
                            onClick={() => this.sharedMethods.handleClickOpen(this.state)}
                            classes={{
                                root: 'add-button'
                            }} >
                        <Icon>add</Icon>
                    </Fab>
                </div>

                <div className="d-flex" style={{marginBottom:'10px'}}>

                    <Select
                        autoFocus
                        tabIndex="1"
                        styles={this.customStyles}
                        key='search-type-select'
                        isMulti={false}
                        isSearchable={true}
                        placeholder='Client Search By'
                        value={this.state.searchMethod}
                        onChange={ (e) => this.clientSearchKeyChange(e) }
                        options={
                            [
                                {
                                    value: 'name',
                                    label: 'Name'
                                },
                                {
                                    value: 'id',
                                    label: 'ID'
                                },
                                {
                                    value: 'acronym',
                                    label: 'Acronym'
                                }
                            ]
                        }
                    />

                </div>

                <div className="d-flex">
                    <TextField
                        inputRef={this.searchRef}
                        tabIndex="2"
                        id="search-box"
                        label="Search Clients"
                        type="text"
                        value={this.state.search ? this.state.search : ''}
                        onChange={(e) => this.searchClients(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment classes={{
                                    root: 'search-icon'
                                }}>
                                    <Icon>search</Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>

                <div className='d-flex'>
                     <small>
                        {this.getPages()}
                    </small>
                </div>

                {
                    this.getShowTags()
                }

            </div>,

            <FormDialog
                key="dialog"
                id="client-dialog"
                entityName="Client"
                open={this.state.open}
                edit={this.state.edit}
                errors={this.state.errors}
                inputs={this.state.inputs}
                form={this.form}
                submit={() => this.submitClient()}
                handleInputChange={(e, key) => this.sharedMethods.handleInputChange(e, key, 'inputs')}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                )}
            />,

            <ConfirmDelete
                key="delete"
                confirmDeleteOpen={this.state.confirmDeleteOpen}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                )}
                deleteIds={this.state.deleteIds}
                delete={(id, index) => this.deleteClient(id, index)}
            />

        ];
    }
}

export default Clients;